import React, { useRef, useDeferredValue } from 'react';
import { useLocale } from '@unisporkal/localization';
import { AUTOSUGGEST_ENDPOINT, ISTOCK_PLACEHOLDERS } from '../../../constants';
import { useOutsideAlerter, useSearchBar } from '../../../hooks';
import MobileMediaFilter from '../MediaFilter/MobileMediaFilter';
import DesktopMediaFilter from '../MediaFilter/DesktopMediaFilter';
import { AutoSuggest, SearchBar, SearchBox, SearchByImage } from '../../shared';

import styles from './IstockSearchBar.module.scss';
import searchBarStyles from '../../shared/SearchBar/SearchBar.istock.module.scss';

const VerticalRule = () => <span className={styles.verticalRule} />;

const IstockSearchBar = () => {
  const containerRef = useRef(null);
  useOutsideAlerter(containerRef);
  const searchBar = useSearchBar();
  const locale = useLocale();
  const showMobileMediaFilter = useDeferredValue(
    searchBar.options.mobileMediaFilter && searchBar.inFocus
  );

  const autoSuggestOptions = {
    endpoint: AUTOSUGGEST_ENDPOINT,
    siteLocale: searchBar.locale || locale,
    searchAssetType: searchBar.searchParameters.assettype || 'image',
    searchAssetFamily: searchBar.searchParameters.family || 'any',
  };

  return (
    <SearchBar styles={searchBarStyles}>
      <div
        className={styles.container}
        ref={containerRef}
      >
        <div className={styles.holder}>
          <DesktopMediaFilter />
          <VerticalRule />
          <SearchBox placeholders={ISTOCK_PLACEHOLDERS} />
          <SearchByImage />
        </div>
        {showMobileMediaFilter && (
          <div className={styles.mediaFilter}>
            <MobileMediaFilter />
          </div>
        )}
        <AutoSuggest options={autoSuggestOptions} />
      </div>
    </SearchBar>
  );
};

export default IstockSearchBar;
